<template>
  <mainPop popWidth=1120px popClass='mainPopClass' @confirm='watermarkPop = false' :openhint=watermarkPop titleText=水印设置
    @cancel='resetWatermark(), watermarkPop = false'>
    <template v-slot:el-popBody>
      <div class="watermarkbox">
        <div class="watermark-left">
          <div class="topSelect dp-f jc-fe ai-c">
              文件选择：
              <el-select size="mini" v-model="state.nowContract" @change="contractChange" value-key="id">
                <el-option v-for="(item, index) in state.contractOptions" :key="item.id" :label="item.fileChname"
                  :value="item" />
              </el-select>
          </div>
          <div class="img" ref="imgRef">
            <el-image :src="state.nowContract.pages[state.nowpage - 1].fileUrl" ref="contractImgRef"></el-image>
            <template v-for="(el, ind) in state.nowContract.watermark" :key="el.id">
              <div class="ws-p">
                <div v-if="el.positionType < 5 || el.positionType == 6" :class="['class' + (el.positionType + 1)]"
                  :style="getChangeStyle(el, ind)">{{ el.facade }}</div>
                <div class="flooding" v-else-if="el.positionType == 5">
                  <div class="ml-50 mt-50" :style="getChangeStyle(el, ind)" v-for="i in 299" :key="i">{{ el.facade }}</div>
                </div>
              </div>
            </template>
          </div>
          <getpages class="mb-10" @Emit="getpageEmit" :maxpages="state.maxpages" ref="getpagesRef"></getpages>
        </div>
        <div class="watermark-right">
          <template v-if="state.nowContract.watermark.length > 0">
            <el-select class="bigSelect" size="large" v-model="state.watermarkId">
              <el-option v-for="(item, index) in state.nowContract.watermark" :key="item.watermarkId" :label="'水印' + (index + 1)"
                :value="item.watermarkId" />
            </el-select>
            <el-image class="deleLed cu-p" @click="deleItem(state.watermarkId)"
              :src="require('@/assets/img/initiateContract/deleLed.png')" />
            <div class="setting">
              <el-form :label-width="100">
                <el-form-item label="水印外观">
                  <el-select v-model="state.nowContract.watermark[watermarkIndex].type" class='w100' @change="typeChange">
                    <el-option v-for="(item, index) in state.typeList" :key=index :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="文字" v-show="state.nowContract.watermark[watermarkIndex].type==0">
                  <el-input
                    v-model="state.nowContract.watermark[watermarkIndex].facade"
                    maxlength="20"
                    placeholder="请输入水印文字(20个字符以内)"
                    show-word-limit
                    type="textarea"
                  />
                </el-form-item>
                <el-form-item label="字号">
                  <div class="dp-fc jc-sb w100">
                    <el-select v-model="state.nowContract.watermark[watermarkIndex].watermarkSize" style="width:178px;" :disabled="state.nowContract.watermark[watermarkIndex].positionType==6" placeholder="默认">
                      <el-option v-for="(item, index) in state.watermarkSizeList" :key=index :label="item" :value="item" />
                    </el-select>
                    <el-color-picker v-model="state.nowContract.watermark[watermarkIndex].color" color-format="rgb"/>
                  </div>
                </el-form-item>
                <el-form-item label="透明度(%)">
                  <el-slider v-model="state.nowContract.watermark[watermarkIndex].transparency" show-input />
                </el-form-item>
                <el-form-item label="位置">
                  <div class="dp-f fw-w">
                    <template v-for="(n, i) in state.locationImg" :key="i">
                      <el-tooltip class="box-item" effect="dark" :content="n.title" placement="top">
                        <el-image class="lm mb-10 cu-p" :src="n.imageA"
                        v-if="state.nowContract.watermark[watermarkIndex].positionType == i" />
                        <el-image class="lm mb-10 cu-p" :src="n.image" @click="handlLoca(i)" v-else/>
                      </el-tooltip>
                    </template>
                  </div>
                </el-form-item>
                <el-form-item label="角度">
                  <el-select v-model="state.nowContract.watermark[watermarkIndex].inclination">
                    <el-option v-for="(item, index) in state.inclinationList" :key=index :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <div class="btn dp-fc">
            <oabutton title="添加水印" @buttonclick='addWatermark' width=321 height=63 CSStype=2 :imgname="(ThemeTypeIndex == 1||ThemeTypeIndex == 2||ThemeTypeIndex == 3)?'darkAdd2.png':'darkAdd.png'">
            </oabutton>
          </div>
        </div>
      </div>
    </template>
  </mainPop>
</template>

<script setup>
import { ref, unref, reactive, computed, nextTick } from "vue";
import { getwatermarkData } from "@/utils/server/selectdata.js"
import { handleCofirm,handleMessage } from "@/utils/server/confirm.js"
import {useStore} from "vuex"
import mainPop from "@/components/dialog/mainPop.vue";
import getpages from "@/components/pagination/getpages.vue"
const store = useStore();
const userInfo = JSON.parse(window.sessionStorage.user).user_info;
const ThemeTypeIndex= computed(() => {
  return store.state.theme.typeIndex
})//当前选中主题下标
const getpagesRef = ref()//分页组件ref
const watermarkPop = ref(false);//弹框显示
const contractImgRef = ref()
const imgRef = ref()

// YQ478-32487
const state = reactive({
  nowContract: {},//当前选中合同文件数据
  contractOptions: [],//当前选中合同文件下拉数据
  waterDatas: [],//取消缓存的水印数据
  watermarkId: 1,//当前展示水印id
  // typeList: [{label:'文字水印',value:'0'}, {label:'文件编号',value:'1'}],//水印外观
  typeList: [{label:'文字水印',value:'0'}],//水印外观
  watermarkSizeList: [],//字号
  locationImg: getwatermarkData(),//水印位置
  inclinationList: [],//角度
  nowpage: 1,//当前页码数
  maxpages: 1,//最大页码数
});
// 重置水印数据
const resetWatermark = () => {
  state.waterDatas.forEach((item, ind) => {
    state.contractOptions[ind].watermark = item
  })
}
// 签署设置执行生成
const show = ((filesContracts) => {
  watermarkPop.value = true
  state.contractOptions = filesContracts
  state.waterDatas = []
  state.contractOptions.forEach(item => {
    state.waterDatas.push(JSON.parse(JSON.stringify(item.watermark)))
  })
  changeNowContract(filesContracts[0])
})
// 选择文件Change方法
const contractChange = ((val) => {
  changeNowContract(val)
})
// 改变当前选中合同文件
const changeNowContract = (data) => {
  state.nowContract = data
  state.maxpages = data.pages.length
  // 如果存在水印数据则生成第一条
  if (state.nowContract.watermark.length > 0) {
    state.watermarkId = state.nowContract.watermark[0].watermarkId
  }
  nextTick(() => {
    unref(getpagesRef).setPage(1)
  })
}
// 分页组件返回新页码
const getpageEmit = ((value) => {
  state.nowpage = value
})
//字体大小生成
for (let i = 10; i < 51; i++) {
  state.watermarkSizeList.push(i);
}
//角度生成
for (let i = 0; i < 181; i++) {
  if(i%15==0){
    state.inclinationList.push({
      label: i + "°",
      value: i,
    });
  }
}
//删除水印
const deleItem = (id) => {
  handleCofirm('确定删除该水印？').then(()=>{
    state.nowContract.watermark.forEach((e, i) => {
      if (e.watermarkId == id) {
        state.nowContract.watermark.splice(i, 1)
      }
    })
    // 如果存在水印数据则生成第一条
    if (state.nowContract.watermark.length > 0) {
      state.watermarkId = state.nowContract.watermark[0].watermarkId
    }
    handleMessage('删除成功!','success')
  })
};

//根据水印id查出索引
const watermarkIndex = computed(() => {
  let index = 0
  state.nowContract.watermark.forEach((e, i) => {
    if (e.watermarkId == state.watermarkId) {
      index = i
    }
  })
  return index
})
//点击位置图
const handlLoca = (index) => {
  if(index==6){
    state.nowContract.watermark[watermarkIndex.value].watermarkSize=''
  }else{
    if(state.nowContract.watermark[watermarkIndex.value].watermarkSize==''){
      state.nowContract.watermark[watermarkIndex.value].watermarkSize=20
    }
  }
  state.nowContract.watermark[watermarkIndex.value].positionType = index;
  const height = unref(contractImgRef).$el.offsetHeight
  unref(imgRef).style.setProperty("--imgHeight", height + 'px')
};
//添加水印
const addWatermark = () => {
  const facade = userInfo.sysCompany ? userInfo.sysCompany.companyName : userInfo.chnname
  // console.log(facade)
  let id = new Date().getTime() + Math.round(100 * Math.random())
  state.nowContract.watermark.push({
    watermarkId: id,
    type:'0',//水印外观
    facade: facade,
    color: "rgb(191, 191, 191)",//字体颜色
    transparency: 50,//透明度
    positionType: 0,//水印位置类型
    inclination: 0,//水印倾斜度
    watermarkSize: 20,//水印字号
  });
  state.watermarkId = id;
  handlLoca(0);
};
//水印样式响应
const getChangeStyle = (el, ind) => {
  let positionType = el.positionType;
  let isCentent = positionType == 4 || positionType == 6 ? "translate(-50%, -50%)" : "";
  const inclination = 360 - el.inclination
  return {
    fontSize: el.watermarkSize + "px",
    color: el.color,
    opacity: el.transparency / 100,
    transform: "rotate(" + inclination + "deg) " + isCentent,
  };
};
//水印外观改变事件
const typeChange=(value)=>{
  // if(value=='0'){
  //   state.nowContract.watermark[watermarkIndex.value].facade ='YQ478-32487'//字体绑定值
  // }
}
defineExpose({
  show
});
</script>

<style>
</style>

<style lang="scss" scoped>
@import "@/styles/color/value.scss";

.watermarkbox {
  display: flex;
  .watermark-left {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid #e7e7e7;
    overflow: auto;
    height: 100%;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 5px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      //background-color: #8c8c8c;
      background-color: rgba(0, 0, 0, 0.25);
    }
    &::-webkit-scrollbar-track {
      background-color: #f6f6f6;
    }
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border: 0;
    }
    .img {
      --imgHeight: 803px;
      margin-bottom: 10px;
      min-height: 400px;
      max-height: 500px;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.11);
      position: relative;
      text-transform: uppercase;
      overflow: auto;
      font-family: SimSun;
      .ws-p {
        white-space: pre;
      }
      .class1 {
        position: absolute;
        top: 3.333%;
        left: 10%;
      }
      .class2 {
        position: absolute;
        top: 3.333%;
        right: 10%;
      }
      .class3 {
        position: absolute;
        top: calc(var(--imgHeight) * 0.96);
        left: 10%;
      }
      .class4 {
        position: absolute;
        top: calc(var(--imgHeight) * 0.96);
        // bottom: calc(3.333% * 1.5);
        right: 10%;
      }
      .class5,
      .class7 {
        position: absolute;
        top: calc(var(--imgHeight) * 0.5);
        left: 50%;
      }
      .class7 {
        width: 100%;
        text-align: center;
        font-size:90px!important;
      }
      .flooding {
        position: absolute;
        width: 100%;
        height: var(--imgHeight);
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        top: 0;
        left: 0;
        overflow: hidden;
      }
    }
    .topSelect {
      display: flex;
      height: 60px;
      ::v-deep .el-select {
        width: 99px;
        .el-input__wrapper {
          border-radius: 0;
          box-shadow: $active-theme 0 0 0 1px !important;
        }
      }
    }
  }
  .watermark-right {
    width: 485px;
    flex-shrink: 0;
    position: relative;
    .bigSelect {
      width: 100%;
      height: 60px;
      ::v-deep .el-input__wrapper {
        border-radius: 0;
        box-shadow: none !important;
        border-bottom: 1px solid #d8d8d8;
        padding: 1px 30px;
        font-size: 16px;
      }
      ::v-deep .select-trigger {
        height: 100%;

        .el-input {
          height: 100%;
        }
      }
    }
    .deleLed {
      position: absolute;
      top: 20px;
      left: 100px;
    }
    .setting {
      padding: 16px 30px;
      ::v-deep .el-form-item__label {
        font-size: 14px;
      }
      .el-form-item {
        margin-bottom: 10px;
        ::v-deep .el-form-item__content {
          margin-left: 20px;
        }
        ::v-deep .el-color-picker__trigger {
          width: 111px;
          border-radius: 0;
          .el-icon {
            display: none;
          }
        }
        .lm {
          margin-right: 25px;
        }
      }
    }
    .btn {
      width: 100%;
      margin-top: 10px;
    }
  }
}</style>